<template>
  <div class="vg_wrapper" v-loading="loadingFlag">
    <div class="vd_edhea vg_button_group">
      <editHeader
        :isShow="isShow"
        :btn="btn"
        :strForm="mtrlForm"
        @openEdit="openEdit"
        @closeEdit="closeEdit"
        @submit="submit('mtrlForm')"
      />
      <el-button type="primary" size="small" class="vd_export" @click="buttonRefresh()" v-if="isShow">刷新</el-button>
      <el-button type="primary" size="small" class="vd_export1" @click="mtrlToMes()" v-if="isShow && mtrlForm.status === 2">{{
        mtrlSendOrCover === 1 ? '发送' : '覆盖'
      }}</el-button>
    </div>
    <el-row>
      <el-col :md="8" :offset="7" v-if="warningTitle">
        <div class="vd_color_red">！{{ warningTitle }}</div>
      </el-col>
    </el-row>
    <el-form ref="mtrlForm" :model="mtrlForm" :rules="rules" label-width="120px" size="mini" :disabled="isShow">
      <el-row>
        <el-col :md="6">
          <el-form-item label="物料图片">
            <el-upload
              class="avatar-uploader"
              :action="uploadUrl"
              :data="dataBody"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
            >
              <imgPopover v-if="mtrlForm.imge_id" :imgeUrl="mtrlForm.imge_url" :imgeUrlO="mtrlForm.imge_urlO"></imgPopover>
              <i v-else class="el-icon-plus avatar-uploader-icon vg_pointer" :class="{ vd_cussor: isShow }"></i>
            </el-upload>
            <span>仅支持jpg、png、jpeg格式且大小不能超过10MB</span>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="物料编号" prop="mtrl_no">
            <!-- <el-input v-model="mtrlForm.mtrl_no" @input ="mtrlForm.mtrl_no = helper.keepEngNum1(mtrlForm.mtrl_no)" placeholder="请填写账套编号（仅支持数字和英文和-）" minlength="30"/> -->
            <el-tooltip class="item" effect="dark" content="仅限两位大写英文字母" placement="top-center">
              <el-input class="mtrlNo" v-model="mtrlForm.mtrl_no" @input="mtrlNoInput" placeholder="例:CR" show-word-limit>
                <template slot="append">
                  {{ mtrlForm.mtrl_color }}
                  <span v-if="mtrlForm.mtrl_color">C{{ num_no }}</span>
                  <span v-if="mtrlForm.mtrl_type !== 0">{{ showNo }}</span>
                </template>
              </el-input>
            </el-tooltip>
          </el-form-item>
          <el-form-item label="物料名称" prop="mtrl_name">
            <el-input v-model="mtrlForm.mtrl_name" @change="mtrlNameChange" maxlength="30" show-word-limit placeholder="请填写物料名称">
              <template slot="prepend">{{ mtrlForm.colr_class }}</template>
            </el-input>
          </el-form-item>
          <el-form-item label="物料英文名" prop="mtrl_spec_en">
            <el-input v-model="mtrlForm.mtrl_spec_en" maxlength="50" show-word-limit placeholder="请填写物料英文名"></el-input>
          </el-form-item>
          <el-form-item label="物料类型" prop="mtrl_type">
            <el-select v-model="mtrlForm.mtrl_type" placeholder="请选择物料类型" clearable @change="typeChange">
              <el-option v-for="item in mtrlType" :key="item.id" :label="item.label" :value="item.id"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="安全库存" prop="mtrl_stock_limit">
            <el-input
              v-model="mtrlForm.mtrl_stock_limit"
              @input="mtrlForm.mtrl_stock_limit = helper.keepTNum(mtrlForm.mtrl_stock_limit)"
              maxlength="7"
              show-word-limit
              placeholder="请填写库存警告数量"
            ></el-input>
          </el-form-item>
          <el-form-item label="物料单价" prop="mtrl_prod_price">
            <el-input
              disabled
              v-model="mtrlForm.mtrl_prod_price"
              @input="mtrlForm.mtrl_prod_price = helper.keepTNum1(mtrlForm.mtrl_prod_price)"
              @blur="mtrlForm.mtrl_prod_price = helper.calcPrice(mtrlForm.mtrl_prod_price, 4, 1000000)"
              maxlength="11"
              show-word-limit
              placeholder="请填写物料单价"
            >
              <template slot="append">元</template>
            </el-input>
          </el-form-item>
          <el-form-item label="美金单价" prop="mtrl_prod_price">
            <el-input
              v-model="mtrlForm.usd_price"
              @input="mtrlForm.usd_price = helper.keepTNum1(mtrlForm.usd_price)"
              @blur="mtrlForm.usd_price = helper.calcPrice(mtrlForm.usd_price, 4, 1000000)"
              maxlength="11"
              show-word-limit
              placeholder="请填写美金单价"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="物料克重" prop="mtrl_weight" :rules="mtrlForm.mtrl_type == 0 ? [{ required: true }] : [{ required: false }]">
            <el-input
              v-model="mtrlForm.mtrl_weight"
              @input="mtrlForm.mtrl_weight = helper.keepTNum1(mtrlForm.mtrl_weight)"
              @blur="mtrlForm.mtrl_weight = helper.retain1(mtrlForm.mtrl_weight, 2, 10000)"
              maxlength="7"
              show-word-limit
              placeholder="请填写物料克重"
            >
              <template slot="append">克</template>
            </el-input>
          </el-form-item>
          <el-form-item
            label="物料规格"
            prop="mtrl_spec"
            :rules="mtrlForm.mtrl_type === 0 ? [{ required: false }] : [{ required: true, trigger: ['blur', 'change'], message: ' ' }]"
          >
            <el-input
              type="textarea"
              @change="mtrlSpecChange"
              rows="1"
              v-model="mtrlForm.mtrl_spec"
              maxlength="50"
              show-word-limit
              placeholder="请填写物料规格"
            ></el-input>
          </el-form-item>
          <el-form-item label="专属客户" prop="cust_name">
            <el-input disabled v-model="mtrlForm.cust_name" show-word-limit placeholder="请填写专属客户" @click.native="custImport()">
              <template slot="append">
                <el-link :disabled="isShow" type="primary">选择</el-link>
              </template>
              {{ mtrlForm.cust_name }}
            </el-input>
          </el-form-item>
          <el-dialog :title="CustTitle" :visible.sync="MtrlFlag" width="70%">
            <MtrlEditCust @custID="custID" @custName="custName" @custCancel="custCancel"></MtrlEditCust>
          </el-dialog>
        </el-col>
        <el-col :md="8">
          <el-form-item
            label="潘通色号"
            v-if="mtrlForm.mtrl_type == 0"
            prop="mtrl_color"
            :rules="mtrlForm.mtrl_type == 0 ? [{ required: true }] : [{ required: false }]"
          >
            <el-tooltip class="item" effect="dark" content="仅限四位数字" placement="top-start">
              <el-input
                class="mtrlcolor"
                v-model="mtrlForm.mtrl_color"
                @input="mtrlColorInput"
                show-word-limit
                placeholder="仅限四位数字"
                @change="clickSyncColor"
              >
                <template slot="append"><span v-if="mtrlForm.mtrl_color">C</span></template>
              </el-input>
            </el-tooltip>
          </el-form-item>
          <el-form-item label="颜色大类" prop="colr_class" class="itemBtn">
            <el-select filterable v-model="mtrlForm.colr_class" placeholder="请选择颜色大类" :disabled="mtrlForm.mtrl_type === 0">
              <el-option v-for="item in optnColorList" :key="item.param1" :label="item.param1" :value="item.param1"> </el-option>
            </el-select>
            <!-- <el-button size="mini" type="info" :disabled="mtrlForm.mtrl_color.length!==4" @click="clickSyncColor">同步</el-button> -->
          </el-form-item>

          <el-form-item label="供应商户" prop="supp_id">
            <el-select disabled filterable v-model="mtrlForm.supp_id" placeholder="请选择供应商" clearable>
              <el-option v-for="item in suppType" :key="item.supp_id" :label="item.supp_name" :value="item.supp_id"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="物料单位" prop="mtrl_unit">
            <el-select v-model="mtrlForm.mtrl_unit" placeholder="请选择物料类型" clearable>
              <el-option v-for="item in mtrlTypeGroupOpt" :key="item.id" :label="item.param1" :value="item.param1"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="物料毛高" prop="mtrl_thick" :rules="mtrlForm.mtrl_type == 0 ? [{ required: true }] : [{ required: false }]">
            <el-input
              v-model="mtrlForm.mtrl_thick"
              @input="mtrlForm.mtrl_thick = helper.keepTNum1(mtrlForm.mtrl_thick)"
              @blur="mtrlForm.mtrl_thick = helper.retain1(mtrlForm.mtrl_thick, 2, 1000)"
              maxlength="6"
              show-word-limit
              placeholder="请填写物料毛高"
            >
              <template slot="append">毫米</template>
            </el-input>
          </el-form-item>
          <el-form-item label="物料成分" prop="mtrl_comp">
            <el-select v-model="mtrlForm.mtrl_comp" placeholder="请选择物料成分" clearable filterable>
              <el-option v-for="item in compType" :key="item.id" :label="item.param1" :value="item.param1"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="物料门幅" prop="mtrl_width" :rules="mtrlForm.mtrl_type == 0 ? [{ required: true }] : [{ required: false }]">
            <el-input
              v-model="mtrlForm.mtrl_width"
              maxlength="9"
              show-word-limit
              @blur="mtrlForm.mtrl_width = helper.calcPrice(mtrlForm.mtrl_width, 4, 10000)"
              @input="mtrlForm.mtrl_width = helper.keepTNum1(mtrlForm.mtrl_width)"
              placeholder="请填写物料门幅"
            >
              <template slot="append">米</template>
            </el-input>
          </el-form-item>

          <el-form-item label="规格英文名" prop="mtrl_espec">
            <el-input
              type="textarea"
              rows="1"
              v-model="mtrlForm.mtrl_espec"
              maxlength="50"
              show-word-limit
              placeholder="请填写规格英文名"
            ></el-input>
          </el-form-item>

          <el-form-item label="其他备注" prop="mtrl_remark">
            <el-input
              type="textarea"
              rows="1"
              v-model="mtrlForm.mtrl_remark"
              maxlength="150"
              show-word-limit
              placeholder="请填写物料备注"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <div class="vd_solidDiv"></div>
      <el-row>
        <el-col :md="7">
          <div class="vd_hidden">111</div>
        </el-col>
        <el-col :md="16">
          <div>
            <div class="vd_but vg_mb_8">
              <el-button plain size="small" @click="addRow()">新增</el-button>
            </div>
            <MtrlImport
              :isShow="isShow"
              @checkboxChange="checkboxChange"
              @inputChange="inputChange"
              @selectChange="selectChange"
              @deleteClick="deleteClick"
              :mtrlForm="mtrlForm"
            ></MtrlImport>
          </div>
        </el-col>
      </el-row>
      <el-row class="vg_mt_16 vd_bortop">
        <el-col>
          <inputUser :isCookie="false" :stffForm="stffForm" ref="userData" v-if="mtrlForm.stff_name"></inputUser>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { mtrlAPI } from '@api/modules/mtrl';
import { optnAPI } from '@api/modules/optn';
import { openAPI } from '@api/modules/open';
import { suppAPI } from '@api/modules/supp';
import { imgeAPI } from '@api/modules/imge';
import { colrAPI } from '@/api/modules/colr.js';
import editHeader from '@/views/component/editHeader';
import inputUser from '@/views/component/inputUser';
import imgPopover from '@/views/component/imgPopover';
import MtrlImport from './Componet/MtrlImport.vue';
import MtrlEditCust from './Componet/MtrlEditCust.vue';

export default {
  name: 'mtrlEditMain',
  components: {
    editHeader,
    inputUser,
    imgPopover,
    MtrlImport,
    MtrlEditCust
  },
  data() {
    return {
      rules: {
        mtrl_no: [{ required: true, trigger: ['blur', 'change'], message: ' ' }],
        mtrl_name: [{ required: true, trigger: 'blur', message: ' ' }],
        mtrl_type: [{ required: true, trigger: 'blur', message: ' ' }],
        mtrl_unit: [{ required: true, trigger: 'blur', message: ' ' }],
        mtrl_thick: [{ required: true, trigger: ['blur', 'change'], message: ' ' }],
        mtrl_comp: [{ required: true, trigger: 'blur', message: ' ' }],
        mtrl_stock_limit: [{ required: true, trigger: 'blur', message: ' ' }],
        mtrl_prod_price: [{ required: true, trigger: 'blur', message: ' ' }],
        mtrl_width: [{ required: true, trigger: ['blur', 'change'], message: ' ' }],
        mtrl_color: [{ required: true, trigger: ['blur', 'change'], message: ' ' }],
        supp_id: [{ required: true, trigger: 'blur', message: ' ' }],
        mtrl_weight: [{ required: true, trigger: 'blur', message: ' ' }]
        // colr_class:[{required:true,trigger:'change ',message:" "}]
      },
      mtrlForm: {
        mtrl_no: null,
        mtrl_name: null,
        mtrl_spec_en: null,
        mtrl_type: null,
        mtrl_spec: null,
        mtrl_espec: null,
        mtrl_color: '',
        mtrl_weight: null,
        mtrl_width: null,
        mtrl_unit: null,
        imge_id: null,
        imge_url: null,
        imge_urlO: null,
        mtrl_thick: null,
        mtrl_comp: null,
        mtrl_stock_limit: null,
        mtrl_prod_price: null,
        usd_price: null,
        mtrl_remark: null,
        cust_id: null,
        cust_name: null,
        supp_id: null,
        user_id: null,
        stff_id: null,
        dept_id: null,
        status: 0,
        key: 0,
        colr_class: null,
        mtrl_info_list: []
      },
      showNo: null,
      mtrlformMtrlNo: null,
      key: 0,
      isShow: true,
      uploadUrl: this.helper.modePrepend(imgeAPI.uploadImage),
      btn: {},
      stffForm: {},
      imgForm: {
        imge_id: null,
        imge_url: null,
        imge_urlO: null
      },
      dataBody: {
        file: /\.(png|jpg|gif|jpeg)$/,
        cut: true,
        keep: false,
        x: 400,
        y: 400
      },
      mtrlType: [
        { id: 0, label: '原面料(The Original Fabric)' },
        { id: 1, label: '辅料(Ingredients)' },
        { id: 2, label: '包材(Packing Materials)' }
      ],
      mtrlTypeGroupOpt: [],
      suppType: [],
      show: false,
      loadingFlag: true,
      compType: [],
      num_no: null,
      warningTitle: '',
      mtrlSendOrCover: null,
      optnColorList: [],
      mtrlInfoListCopy: [],
      checkedIndex: 0,
      MtrlFlag: false,
      CustTitle: '添加物料专属客户',
      imgTypeFlag: true
    };
  },
  created() {
    this.initData();
  },
  watch: {},
  methods: {
    initData() {
      this.loadingFlag = true;
      this.getmtrlInfo();
      this.getMtrlType();
      this.getCompType();
      this.getSuppsList();
      this.getOptnColor();
      this.getContry();
      setTimeout(() => {
        // this.getMesMtrlBy();
      }, 1000);
    },
    //打开专属客户弹窗
    custImport() {
      if (!this.isShow) {
        this.MtrlFlag = true;
      }
    },
    //关闭专属客户弹窗
    custCancel() {
      this.MtrlFlag = false;
    },
    //获取颜色大类option
    getOptnColor() {
      get(optnAPI.getAllContent, { perm_id: 10010 })
        .then(res => {
          if (res.data.code === 0) {
            this.optnColorList = res.data.data;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    //启用编辑
    openEdit() {
      this.isShow = false;
      this.$emit('isShow', this.isShow);
    },
    //撤销编辑
    closeEdit() {
      this.$confirm('是否撤销编辑?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          this.isShow = true;
          this.$emit('isShow', this.isShow);
          this.show = true;
          this.initData();
          this.$message({
            type: 'info',
            message: '已撤销编辑!'
          });
        })
        .catch(() => {});
    },
    // 获取发送还是覆盖
    getMesMtrlBy() {
      get(openAPI.getMesMtrlByMtrlNo, { mtrl_no: this.mtrlformMtrlNo })
        .then(res => {
          if (res.data.code === 0) {
            this.mtrlSendOrCover = res.data.code;
            let temp = {};
            temp.show = true;
            temp.mtrl_id = this.mtrlForm.mtrl_id;
            this.$emit('showButton', temp);
          } else if (res.data.code === 1) {
            this.mtrlSendOrCover = res.data.code;
            let temp = {};
            temp.show = false;
            temp.mtrl_id = this.mtrlForm.mtrl_id;
            this.$emit('showButton', temp);
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 同步mes
    mtrlToMes() {
      this.$confirm(this.mtrlSendOrCover === 1 ? '是否发送' : '是否覆盖', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          post(openAPI.mtrlToMes, { mtrl_id: this.mtrlForm.mtrl_id })
            .then(res => {
              if (res.data.code === 0) {
                this.getMesMtrlBy();
                this.$message({
                  type: 'success',
                  message: this.mtrlSendOrCover === 1 ? '发送成功' : '覆盖成功'
                });
              } else {
                let mg = res.data.msg;
                let tp = 'error';
                this.$message({ message: mg, type: tp });
              }
            })
            .catch(res => {
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({ message: mg, type: tp });
            });
        })
        .catch(() => {});
    },
    //提交表单
    submit(formName) {
      this.$confirm('确认提交?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$refs[formName].validate(valid => {
            if (valid) {
              this.saveInfo();
            } else {
              console.log('error submit!');
              this.$message.warning('请把必填内容填写完整！');
              return false;
            }
          });
        })
        .catch(() => {});
    },
    // 保存
    saveInfo() {
      let mtrlForm = JSON.parse(JSON.stringify(this.mtrlForm));
      let temp = false;
      if (mtrlForm.mtrl_type == 0) {
        mtrlForm.mtrl_color = mtrlForm.mtrl_color + 'C';
        mtrlForm.mtrl_no = mtrlForm.mtrl_no + '' + mtrlForm.mtrl_color;
      }
      if (mtrlForm.colr_class) {
        mtrlForm.mtrl_name = mtrlForm.colr_class + mtrlForm.mtrl_name;
      }
      this.mtrlInfoListCopy = this.mtrlInfoListCopy.filter(item =>
        mtrlForm.mtrl_info_list.every(item1 => item.mtrl_info_id !== item1.mtrl_info_id)
      );
      for (let i = 0; i < this.mtrlInfoListCopy.length; i++) {
        this.mtrlInfoListCopy[i].destroy_flag = 1;
      }
      for (let i in mtrlForm.mtrl_info_list) {
        if (mtrlForm.mtrl_info_list[i].mtrl_info_checked) {
          mtrlForm.mtrl_info_list[i].mtrl_info_checked = 1;
        } else if (!mtrlForm.mtrl_info_list[i].mtrl_info_checked) {
          mtrlForm.mtrl_info_list[i].mtrl_info_checked = 0;
        }
      }
      for (let i in mtrlForm.mtrl_info_list) {
        if (mtrlForm.mtrl_info_list[i].mtrl_info_checked === 1) {
          temp = true;
        }
      }
      mtrlForm.mtrl_info_list = mtrlForm.mtrl_info_list.concat(this.mtrlInfoListCopy);
      if (temp) {
        post(mtrlAPI.editMtrl, mtrlForm)
          .then(res => {
            if (res.data.code === 0) {
              this.$message({
                type: 'success',
                message: '保存成功'
              });
              this.warningTitle = '';
              this.isShow = true;
              this.$emit('isShow', this.isShow);
              this.initData();
            } else if (res.data.code === 7) {
              this.$message({
                type: 'error',
                message: '物料编号重复'
              });
              this.mtrlForm.mtrl_color = parseInt(this.mtrlForm.mtrl_color);
              this.mtrlForm.mtrl_no = this.mtrlForm.mtrl_no.substr(0, 2);
            } else if (res.data.code === 888) {
              let mg = res.data.msg;
              this.warningTitle = mg;
              let tp = 'error';
              this.$message({ message: mg, type: tp });
              if (res.data.msg === '物料编号格式输入错误!') {
                this.mtrlForm.mtrl_no = null;
              } else {
                this.mtrlForm.mtrl_no = null;
                this.mtrlForm.mtrl_color = null;
                this.mtrlForm.mtrl_width = null;
                this.mtrlForm.mtrl_thick = null;
              }
            } else {
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({ message: mg, type: tp });
              // this.initData();
            }
          })
          .catch(res => {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          });
      } else {
        this.$message.warning('供应商必须有一个默认商户');
      }
    },
    // 获取单位
    getMtrlType() {
      get(optnAPI.getAllContent, { perm_id: 10005 })
        .then(res => {
          if (res.data.code === 0) {
            this.mtrlTypeGroupOpt = res.data.data;
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 获取成分
    getCompType() {
      get(optnAPI.getAllContent, { perm_id: 10008 })
        .then(res => {
          if (res.data.code === 0) {
            this.compType = res.data.data;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 获取供应商信息
    getSuppsList() {
      get(suppAPI.getSuppsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.suppType = res.data.data;
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 获取物料名称名字
    getContry() {
      get(optnAPI.getOptnByPermId, { perm_id: 10013 })
        .then(res => {
          if (res.data.code === 0) {
            this.mtrlNameList = res.data.data.form.optn_cntt_list;
          }
        })
        .catch(() => {});
    },
    // 刷新按钮
    buttonRefresh() {
      this.initData();
    },
    // custID子传父值
    custID(val) {
      if (val) {
        this.mtrlForm.cust_id = val.join(',');
      } else {
        this.mtrlForm.cust_id = null;
      }
    },
    custName(val) {
      if (val) {
        this.mtrlForm.cust_name = val.join(',');
      } else {
        this.mtrlForm.cust_name = null;
      }
    },
    //取消
    cancel() {
      this.$confirm('确定取消新增?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          this.resetForm('mtrlForm');
          this.$router.go(-1);
          this.$message({
            type: 'info',
            message: '已取消新增!'
          });
        })
        .catch(() => {});
    },
    // 新增字表数据
    addRow() {
      let temp = {};
      if (this.mtrlForm.supp_id) {
        temp.supp_id = this.mtrlForm.supp_id;
      } else {
        temp.supp_id = null;
      }
      if (this.mtrlForm.mtrl_prod_price) {
        temp.mtrl_prod_price = this.mtrlForm.mtrl_prod_price;
      } else {
        temp.mtrl_prod_price = '0.0000';
      }
      temp.mtrl_info_checked = false;
      temp.key = this.mtrlForm.mtrl_info_list.length;
      this.mtrlForm.mtrl_info_list.push(temp);
    },
    // 删除子表数据
    deleteClick(val) {
      if (this.mtrlForm.mtrl_info_list.length <= 1) {
        this.$message({
          type: 'warning',
          message: '至少保留一条'
        });
      } else {
        let flag = false;
        if (this.mtrlForm.mtrl_info_list[val.key].mtrl_info_checked) {
          flag = true;
        }
        this.mtrlForm.mtrl_info_list.splice(val.key, 1);
        for (let i in this.mtrlForm.mtrl_info_list) {
          this.mtrlForm.mtrl_info_list[i].key = i;
        }
        if (flag) {
          this.mtrlForm.mtrl_info_list[0].mtrl_info_checked = true;
          this.checkedIndex = 0;
          this.mtrlForm.mtrl_prod_price = this.mtrlForm.mtrl_info_list[0].mtrl_prod_price;
          this.mtrlForm.supp_id = this.mtrlForm.mtrl_info_list[0].supp_id;
        } else {
          for (let i in this.mtrlForm.mtrl_info_list) {
            if (this.mtrlForm.mtrl_info_list[i].mtrl_info_checked) {
              this.checkedIndex = i;
            }
          }
        }
      }
    },
    // 狭隘拉框更换
    selectChange(val) {
      this.mtrlForm.supp_id = this.mtrlForm.mtrl_info_list[val].supp_id;
    },
    // 价格变动
    inputChange(val) {
      this.mtrlForm.mtrl_prod_price = this.helper.haveFour(this.mtrlForm.mtrl_info_list[val].mtrl_prod_price);
    },
    // 更换复选框
    checkboxChange(val, val1) {
      if (val.mtrl_info_checked) {
        let flag = 0;
        for (let i in this.mtrlForm.mtrl_info_list) {
          if (this.mtrlForm.mtrl_info_list[i].mtrl_info_checked) {
            flag += 1;
          }
        }
        if (flag > 1) {
          let ind = JSON.parse(JSON.stringify(this.checkedIndex));
          this.mtrlForm.mtrl_info_list[ind].mtrl_info_checked = false;
          this.checkedIndex = val1;
          this.mtrlForm.mtrl_prod_price = this.mtrlForm.mtrl_info_list[val1].mtrl_prod_price;
          this.mtrlForm.supp_id = this.mtrlForm.mtrl_info_list[val1].supp_id;
        } else {
          this.checkedIndex = val1;
          this.mtrlForm.mtrl_prod_price = this.mtrlForm.mtrl_info_list[val1].mtrl_prod_price;
          this.mtrlForm.supp_id = this.mtrlForm.mtrl_info_list[val1].supp_id;
        }
      } else {
        let flag = 0;
        for (let i in this.mtrlForm.mtrl_info_list) {
          if (this.mtrlForm.mtrl_info_list[i].mtrl_info_checked) {
            flag += 1;
          }
        }
        if (flag === 0) {
          this.mtrlForm.mtrl_info_list[val1].mtrl_info_checked = true;
          this.mtrlForm.mtrl_prod_price = this.mtrlForm.mtrl_info_list[val1].mtrl_prod_price;
          this.mtrlForm.supp_id = this.mtrlForm.mtrl_info_list[val1].supp_id;
          this.checkedIndex = val1;
        }
      }
    },
    //表单重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // 成功上传
    handleAvatarSuccess(res) {
      if (res.code === 0) {
        if (this.imgTypeFlag) return this.$message.warning('仅支持jpg、png、jpeg格式的图片');
        if (res.data.imge_id) {
          this.mtrlForm.imge_id = res.data.imge_id;
        }
        let updateTime = JSON.parse(JSON.stringify(new Date().getTime()));
        let urlEng = JSON.parse(JSON.stringify(res.data.imge_url));
        this.mtrlForm.imge_url = this.helper.picUrl(urlEng, 's', updateTime);
        this.mtrlForm.imge_urlO = this.helper.picUrl(urlEng, 'l', updateTime);
      } else {
        let mg = res.msg;
        let tp = 'error';
        this.$message({ message: mg, type: tp });
      }
    },
    // 上传头像前
    beforeAvatarUpload(res) {
      this.imgTypeFlag = false;
      let str = res.name.substr(res.name.indexOf('.') + 1, res.name.length);
      let imgTypeList = ['jpg', 'png', 'jpeg'];
      if (imgTypeList.indexOf(str) === -1) this.imgTypeFlag = true;
    },
    // 获取表单信息
    getmtrlInfo() {
      this.mtrlForm.mtrl_id = this.$route.query.form_id;
      get(mtrlAPI.getMtrlById, { mtrl_id: this.mtrlForm.mtrl_id })
        .then(res => {
          if (res.data.code === 0) {
            this.mtrlForm = JSON.parse(JSON.stringify(res.data.data.form));
            this.mtrlInfoListCopy = JSON.parse(JSON.stringify(res.data.data.form.mtrl_info_list));
            //发送 还是 覆盖 需要
            this.mtrlformMtrlNo = JSON.parse(JSON.stringify(this.mtrlForm.mtrl_no));

            //为原面料时的编号
            this.num_no = res.data.data.form.mtrl_no.substr(res.data.data.form.mtrl_no.length - 1, 1);
            // 原面料 时需要的色号
            this.mtrlForm.mtrl_color = res.data.data.form.mtrl_color.substr(0, res.data.data.form.mtrl_color.length - 1);

            //编号
            this.mtrlForm.mtrl_no = res.data.data.form.mtrl_no.substr(0, 2);
            let urlEng = JSON.parse(JSON.stringify(this.mtrlForm.imge_url));
            if (this.mtrlForm.imge_id) {
              // this.mtrlForm.imge_id = this.mtrlForm.imge_id;
              this.dataBody.imge_id = this.mtrlForm.imge_id;
            } else {
              // this.mtrlForm.imge_id = 0;
              this.dataBody.imge_id = 0;
            }
            let updateTime = JSON.parse(JSON.stringify(new Date().getTime()));
            this.mtrlForm.imge_url = this.helper.picUrl(urlEng, 's', updateTime);
            this.mtrlForm.imge_urlO = this.helper.picUrl(urlEng, 'l', updateTime);
            if (this.mtrlForm.colr_class) {
              if (this.mtrlForm.mtrl_name.indexOf(this.mtrlForm.colr_class) !== -1) {
                let nameind = this.mtrlForm.colr_class.length;
                let nameCopy = this.mtrlForm.mtrl_name.substring(nameind, this.mtrlForm.mtrl_name.length);
                this.mtrlForm.mtrl_name = nameCopy;
              }
            }
            if (this.show) {
              this.mtrlForm.imge_id = this.imgForm.imge_id;
              this.mtrlForm.imge_url = this.imgForm.imge_url;
              this.mtrlForm.imge_urlO = this.imgForm.imge_urlO;
              this.show = false;
            }
            this.imgForm.imge_url = JSON.parse(JSON.stringify(this.mtrlForm.imge_url));
            this.imgForm.imge_id = JSON.parse(JSON.stringify(this.mtrlForm.imge_id));
            this.imgForm.imge_urlO = JSON.parse(JSON.stringify(this.mtrlForm.imge_urlO));
            this.mtrlForm.mtrl_prod_price = this.helper.haveFour(this.mtrlForm.mtrl_prod_price);
            this.mtrlForm.usd_price = this.helper.haveFour(this.mtrlForm.usd_price);
            this.mtrlForm.mtrl_weight = this.helper.reservedDigits(this.mtrlForm.mtrl_weight);
            this.mtrlForm.mtrl_thick = this.helper.reservedDigits(this.mtrlForm.mtrl_thick);
            this.mtrlForm.mtrl_width = this.helper.haveFour(this.mtrlForm.mtrl_width);
            for (let i in this.mtrlForm.mtrl_info_list) {
              this.mtrlForm.mtrl_info_list[i].mtrl_prod_price = this.helper.haveFour(this.mtrlForm.mtrl_info_list[i].mtrl_prod_price);
            }
            // for(let i in this.mtrlInfoListCopy){
            //   this.mtrlInfoListCopy[i].key = Number(i)
            // }
            this.stffForm.stff_name = this.mtrlForm.stff_name;
            this.stffForm.dept_name = this.mtrlForm.dept_name;
            this.stffForm.dept_team_name = this.mtrlForm.dept_team_name;
            this.stffForm.user_id = this.mtrlForm.user_id;
            this.stffForm.dept_id = this.mtrlForm.dept_id;
            this.stffForm.stff_id = this.mtrlForm.stff_id;
            this.btn = res.data.data.btn;
            for (let i in this.mtrlForm.mtrl_info_list) {
              this.mtrlForm.mtrl_info_list[i].key = i;
              if (this.mtrlForm.mtrl_info_list[i].mtrl_info_checked === 1) {
                this.mtrlForm.mtrl_info_list[i].mtrl_info_checked = true;
                this.checkedIndex = Number(i);
              } else if (!this.mtrlForm.mtrl_info_list[i].mtrl_info_checked === 0) {
                this.mtrlForm.mtrl_info_list[i].mtrl_info_checked = false;
              }
            }
            // 为辅料 和 包材时的编号
            if (this.mtrlForm.mtrl_type != 0) {
              this.showNo = res.data.data.form.mtrl_no.substr(2) + '';
            }
            setTimeout(() => {
              this.loadingFlag = false;
            }, 500);
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    mtrlNoInput() {
      this.mtrlForm.mtrl_no = this.helper.keepEngNum1(this.mtrlForm.mtrl_no).toUpperCase();
      this.mtrlForm.mtrl_no = this.mtrlForm.mtrl_no.substr(0, 2);
    },

    mtrlColorInput() {
      this.mtrlForm.mtrl_color = this.helper.keepTNum(this.mtrlForm.mtrl_color);
      this.mtrlForm.mtrl_color = this.mtrlForm.mtrl_color.substr(0, 4);
    },
    // 计算物料英文名
    mtrlNameChange() {
      let flag = false;
      for (let i = 0; i < this.mtrlNameList.length; i++) {
        if (this.mtrlForm.mtrl_name === this.mtrlNameList[i].param1) {
          this.mtrlForm.mtrl_spec_en = this.mtrlNameList[i].param2;
          flag = true;
        }
      }
      if (!flag) {
        this.$message.warning('未匹配到物料英文名');
      }
    },
    // 计算规格英文名
    mtrlSpecChange() {
      for (let i = 0; i < this.mtrlNameList.length; i++) {
        if (this.mtrlForm.mtrl_spec.indexOf(this.mtrlNameList[i].param1) !== -1) {
          let str = this.mtrlForm.mtrl_spec.substring(0, this.mtrlForm.mtrl_spec.indexOf(this.mtrlNameList[i].param1));
          let str1 = this.mtrlForm.mtrl_spec.substring(
            this.mtrlForm.mtrl_spec.indexOf(this.mtrlNameList[i].param1) + this.mtrlNameList[i].param1.length,
            this.mtrlForm.mtrl_spec.length
          );
          let str2 = this.mtrlNameList[i].param2;
          this.mtrlForm.mtrl_espec = str + str2 + str1;
        }
      }
    },
    typeChange() {
      if (this.mtrlForm.mtrl_type != 0) {
        this.mtrlForm.mtrl_color = '';
        this.mtrlForm.colr_class = '';
      } else {
        this.mtrlForm.colr_class = null;
      }
    },
    //同步颜色 大类
    clickSyncColor() {
      get(colrAPI.getColrClassByPantone, {
        colr_pantone: this.mtrlForm.mtrl_color + 'C'
      })
        .then(res => {
          if (res.data.code === 0) {
            this.mtrlForm.colr_class = res.data.data.form.colr_class;
            this.$message.success('同步' + res.data.msg);
            return;
          } else if (res.data.code === 2) {
            this.$message.warning('未匹配到该颜色大类');
            return;
          }
          this.$message.warning(res.data.msg);
        })
        .catch(res => {
          this.$message.warning(res.data.msg);
        });
    }
  }
};
</script>

<style scoped lang="scss">
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader ::v-deep .el-upload--text {
  width: 136px;
  height: 136px;
}
.avatar-uploader .el-upload:hover {
  border-color: $color-primary;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: $color-uploader-icon;
  width: 136px;
  height: 136px;
  line-height: 136px;
  text-align: center;
}
.avatar {
  width: 136px;
  height: 136px;
  display: block;
}
.vd_bortop {
  border-top: #dcdfe6 solid 1px;
  padding-top: 20px;
}
.vd_mrt1 {
  margin-top: 1px;
}
.vd_edhea {
  position: relative;
}
.vd_export {
  position: absolute;
  top: 0;
  left: 65px;
}
.vd_export1 {
  position: absolute;
  top: 0;
  left: 120px;
  background-color: rgb(230, 215, 7) !important;
  border: 1px solid rgb(230, 215, 7) !important;
}
.vd_export1:hover {
  background-color: rgb(232, 222, 93) !important;
  border: 1px solid rgb(232, 222, 93) !important;
}
.vd_popimg {
  width: 500px;
  height: 500px;
}
.vd_cussor {
  cursor: default;
}
::v-deep .mtrlNo .el-input-group__append {
  width: 70%;
  padding: 0;
}
::v-deep .mtrlcolor .el-input-group__append {
  width: 70%;
  padding: 0;
}
::v-deep .el-input-group__append {
  color: #000000;
}
.vd_color_red {
  color: red;
}
::v-deep .itemBtn .el-form-item__content {
  display: flex;
}
.vd_solidDiv {
  height: 1px;
  border: none;
  border-top: 1px dashed #dcdfe6;
  margin: 10px 0 20px 0;
}
.vd_hidden {
  visibility: hidden;
}
</style>
