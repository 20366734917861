<template>
  <el-table style="width: 1000px" :data="mtrlForm.mtrl_info_list" border>
    <el-table-column>
      <template slot="header">
        <span class="vg_deep_red">供应商</span>
      </template>
      <template slot-scope="scope">
        <el-form-item label-width="0" :prop="'mtrl_info_list.' + scope.$index + '.supp_id'" :rules="[{ required: true }]">
          <el-select
            filterable
            @change="changegetSuppsList(scope.row, scope.$index)"
            v-model="mtrlForm.mtrl_info_list[scope.$index].supp_id"
            placeholder="请选择打样部门"
            clearable
          >
            <el-option v-for="item in suppType" :key="item.supp_id" :label="item.supp_name" :value="item.supp_id"> </el-option>
          </el-select>
        </el-form-item>
      </template>
    </el-table-column>
    <el-table-column>
      <template slot="header">
        <span class="vg_deep_red">物料单价</span>
      </template>
      <template slot-scope="scope">
        <el-form-item label-width="0" :prop="'mtrl_info_list.' + scope.$index + '.mtrl_prod_price'" :rules="[{ required: true }]">
          <el-input
            @change="inputChange(scope.row, scope.$index)"
            v-model="mtrlForm.mtrl_info_list[scope.$index].mtrl_prod_price"
            @blur="
              mtrlForm.mtrl_info_list[scope.$index].mtrl_prod_price = helper.calcPrice(
                mtrlForm.mtrl_info_list[scope.$index].mtrl_prod_price,
                4,
                1000000
              )
            "
            @input="
              mtrlForm.mtrl_info_list[scope.$index].mtrl_prod_price = helper.keepTNum1(
                mtrlForm.mtrl_info_list[scope.$index].mtrl_prod_price
              )
            "
            maxlength="11"
            show-word-limit
            placeholder="请填写物料单价"
          >
          </el-input>
        </el-form-item>
      </template>
    </el-table-column>
    <el-table-column width="150" align="center" label="设为默认商户">
      <template slot-scope="scope">
        <el-checkbox
          @change="checkboxChange(scope.row, scope.$index)"
          v-model="mtrlForm.mtrl_info_list[scope.$index].mtrl_info_checked"
        ></el-checkbox>
      </template>
    </el-table-column>
    <el-table-column width="120" align="center" label="操作">
      <template slot-scope="scope">
        <el-link :disabled="isShow" type="primary" class="vg_cursor" @click="deleteClick(scope.row)">删除</el-link>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { get, post } from '@api/request';
import { suppAPI } from '@api/modules/supp';

export default {
  name: 'SmplEditBomChild',
  props: {
    mtrlForm: {
      type: Object,
      required: true
    },
    isShow: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      mtrlFlag: 0,
      selectFlag: false,
      suppType: []
    };
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.getSuppsList();
    },
    // 获取供应商信息
    getSuppsList() {
      get(suppAPI.getSuppsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.suppType = res.data.data;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 修改下拉框
    changegetSuppsList(val, val1) {
      this.getSuppsList();
      if (val.mtrl_info_checked) {
        this.$emit('selectChange', val1);
      }
    },
    // 修改价格
    inputChange(val, val1) {
      if (val.mtrl_info_checked) {
        this.$emit('inputChange', val1);
      }
    },
    // 删除
    deleteClick(val) {
      this.$emit('deleteClick', val);
    },
    // 更改复选框
    checkboxChange(val, val1) {
      let temp = JSON.parse(JSON.stringify(val));
      this.$emit('checkboxChange', temp, val1);
    }
  }
};
</script>

<style scoped></style>
