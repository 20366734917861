<template>
  <div>
    <publicTips></publicTips>
    <el-form :inline="true" size="mini" :model="searchForm" ref="searchForm">
      <el-row class="vg_mt_16">
        <el-col :md="8">
          <el-form-item label="客户姓名:">
            <el-input v-model="searchForm.cust_name" placeholder="按姓名查询" clearable size="small" class="myInput"></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="客户编号:">
            <el-input v-model="searchForm.cust_no" placeholder="请编号查询" clearable size="small" class="myInput"></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="客户国家:">
            <el-select v-model="searchForm.cust_country" placeholder="按国家查询" size="small" clearable filterable>
              <el-option v-for="(item, index) in countryList" :key="index" :label="item" :value="item"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button size="small" type="primary" icon="el-icon-search" @click="searchBtn()" class="vg_ml_16">查询</el-button>
            <el-button size="small" type="info" icon="el-icon-refresh" @click="getSmplsNowO()" class="vg_ml_16">刷新</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <el-row class="vd_mar10">
      <el-button type="danger" size="mini" @click="cancelChoose()">取消选择</el-button>
      <!-- <el-button type="warning" class="handleBtn" size="mini" @click="clearCust()">清空已添加</el-button> -->
      <el-button type="primary" class="vg_mr_8" size="mini" @click="confirmCust()">确认添加</el-button>
    </el-row>
    <el-row class="vg_mt_16">
      <el-table :data="tableData" border v-loading="loadFlag" @selection-change="handleSelectionChange" ref="myTable">
        <el-table-column type="selection" align="center"> </el-table-column>
        <el-table-column prop="cust_name" label="客户姓名" align="center"> </el-table-column>
        <el-table-column prop="cust_no" label="客户编号" align="center"> </el-table-column>
        <el-table-column prop="cust_country" label="所属国家" align="center"> </el-table-column>
      </el-table>
    </el-row>
    <el-row style="margin-top: 20px">
      <el-col :md="24">
        <pubPagination :totalPage="totalPage" @changePageSearch="changePageSearch" ref="pubPagination"> </pubPagination>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { get } from '@api/request';
import { openAPI } from '@api/modules/open';
import { mtrlAPI } from '@api/modules/mtrl';
import pubPagination from '@/components/common/pubPagination';
import publicTips from '@/views/component/publicTips';

export default {
  name: 'MtrlCustList',
  components: {
    pubPagination,
    publicTips
  },

  data() {
    return {
      searchForm: {
        cust_name: '',
        cust_no: '',
        cust_country: ''
      },

      tableData: [],
      totalPage: 0,
      currentPage: 1,
      countryList: [],
      cust_name_list: [],
      loadFlag: true
    };
  },
  created() {
    this.getCustFromErp();
    this.getCountry();
  },
  methods: {
    // 分页查询
    changePageSearch(val) {
      this.currentPage = val;
      this.getCustFromErp();
    },
    // 查找按钮
    searchBtn() {
      this.$refs.pubPagination.currentPage = 1;
      this.currentPage = 1;
      this.loadFlag = true;
      this.changePageSearch(1);
    },
    // 刷新
    getSmplsNowO() {
      this.$refs.pubPagination.currentPage = 1;
      this.currentPage = 1;
      (this.searchForm = {
        cust_name: '',
        cust_no: '',
        cust_country: ''
      }),
        (this.loadFlag = true);
      this.getCustFromErp();
    },
    // 获取客户列表
    getCustFromErp() {
      get(openAPI.getCustFromErp, {
        custName: this.searchForm.cust_name,
        custNo: this.searchForm.cust_no,
        custCountry: this.searchForm.cust_country,
        pageNo: this.currentPage
      })
        .then(res => {
          if (res.data.code === 0) {
            this.tableData = res.data.data.list;
            this.totalPage = res.data.data.totalItem;
            setTimeout(() => {
              this.loadFlag = false;
            }, 500);
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    // 获取国家列表
    getCountry() {
      get(mtrlAPI.getCountry)
        .then(res => {
          if (res.data.code === 0) {
            this.countryList = res.data.data;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    // 表单多选方法
    handleSelectionChange(val) {
      this.cust_name_list = val.map(item => item.cust_name);
      this.multipleSelection = val.map(item => item.cust_id);
    },
    // 取消选择按钮
    cancelChoose() {
      this.$refs.myTable.clearSelection();
      this.$emit('cancelCust');
    },
    // 确认添加客户
    confirmCust() {
      if (this.multipleSelection.length == 0) {
        this.$message({ message: '请先选择至少一条数据！', type: 'error' });
        return;
      }
      this.$emit('custID', this.multipleSelection);
      this.$emit('custName', this.cust_name_list);
      this.close();
    },
    // // 清空选择
    // clearCust(){
    //    this.$emit("custID", null)
    //    this.$emit("custName", null)
    //    this.close()
    // },
    // 关闭窗口
    close() {
      this.$emit('custCancel');
    }
  }
};
</script>
<style>
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}

.clearfix:after {
  clear: both;
}

/* 搜索条件前缀 */
.inputPrefix {
  padding-top: 2px;
  font-size: 15px;
  width: 80px;
  margin-left: 20px;
}

/* 按钮 */
.handleBtn {
  margin-right: 20px;
  margin-top: 20px;
}
.vd_mar10 {
  margin: 10px 0;
}
</style>
