<template>
  <div>
    <docu-about :showButton="showButton"></docu-about>
  </div>
</template>

<script>
import DocuAbout from '@/views/component/docuCommon/docuAbout';

export default {
  name: 'MtrlAddAbout',
  components: { DocuAbout },
  props: {
    showButton: {
      type: Object,
      required: true
    }
  }
};
</script>

<style scoped></style>
